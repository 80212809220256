;(((root, factory) => { // eslint-disable-line no-extra-semi
  if (typeof define === 'function' && define.amd) {
    /**
     * Register our plugin as an anonymous module, defining jQuery as a
     * dependency.
     */
    define(['jquery'], factory);
  } else {
    /**
     * AMD is not available, so use the copy of jQuery attached to our
     * current root (Window by default).
     */
    factory(root.jQuery);
  }
})(window, (jQuery) => {
  const $ = jQuery; // eslint-disable-line id-length

  /**
   * Define the name of your plugin;  this name will be used to access the
   * plugin through jQuery, e.g. $('body').defaultPluginName().
   */
  const pluginName = 'tenancyStatement';

  class Plugin {

    /**
     * The Plugin object construtor.  Initialisation logic should be placed here
     * following the assignment statements.
     */
    constructor(el, options) {
      this.options = options;
      this.element = el;
      this.$element = $(el);
      this.$filter = this.$element.find('form');
      this.$updateBtn = this.$filter.find(this.options.updateBtnSelector);
      this.$printBtn = this.$filter.find(this.options.printBtnSelector);
      this.$loading = this.$element.find(this.options.loadingSelector);
      this.$dates = this.$filter.find('.date');
      this.$to = this.$dates.find(this.options.dateToSelector);
      this.$from = this.$dates.find(this.options.dateFromSelector);
      this.$displayTable = this.$element.find(this.options.displayTableSelector);
      this.$printTable = this.$element.find(this.options.printTableSelector);

      this.onUpdate = this.onUpdate.bind(this);

      this.unregisterBindings();
      this.registerBindings();

      this.initialiseDatePickers();
      this.initialiseTables();

      if (this.options.autoLoad) {
        this.requestStatement().then(response => this.updateTables(response));
      }
    }

    /**
     * Register any relevant event bindings for this Plugin.
     * @return void
     */
    registerBindings() {
      this.$updateBtn.on(`click.${pluginName}`, this.onUpdate);
      this.$printBtn.on(`click.${pluginName}`, Plugin.onPrint);
      this.$filter.on(`submit.${pluginName}`, this.onUpdate);
    }

    /**
     * Unregister any event bindings bound by the Plugin.
     * @return void
     */
    unregisterBindings() {
      this.$updateBtn.off(`.${pluginName}`);
      this.$printBtn.off(`.${pluginName}`);
      this.$filter.off(`.${pluginName}`);
    }

    /**
     * Request a statement with updated values.
     *
     * @param Event evt
     *
     * @return void
     */
    onUpdate(evt) {
      evt.preventDefault();

      this.requestStatement().then(response => this.updateTables(response));
    }

    /**
     * Initialise the bootstrap datepicker instances.
     *
     * @return void
     */
    initialiseDatePickers() {
      this
        .$filter
        .datepicker({
          format: 'dd/mm/yyyy',
          autoclose: true,
          endDate: '0d',
          inputs: this.$dates,
        });
    }

    /**
     * Initialise the table data.
     *
     * @return void
     */
    initialiseTables() {
      const tableConfig = {
        data: [],
        order: [[1, 'desc']],
        columns: [
          { visible: false, orderable: false, searchable: false },
          { visible: false, orderable: false, searchable: false },
          { orderable: false },
          { orderable: false },
          { orderable: false },
        ],
        rowGroup: {
          dataSrc: 0,
        },
        autoWidth: false,
        pagingType: 'simple',
        responsive: {
          details: {
            display: $.fn.dataTable.Responsive.display.childRowImmediate,
          },
        },
      };

      this.$displayTable.dataTable(tableConfig);

      this.$printTable.dataTable({
        ...tableConfig,
        paging: false,
        dom: 't',
      });
    }

    /**
     * Update the table with the given data.
     *
     * @param array data
     *
     * @return void
     */
    updateTables(data) {
      this.$displayTable
        .dataTable()
        .api()
        .clear()
        .rows
        .add(data)
        .draw();

      this.$printTable
        .dataTable()
        .api()
        .clear()
        .rows
        .add(data)
        .draw();
    }

    /**
     * Request statement data from the server.
     *
     * @return jQueryXHR
     */
    requestStatement() {
      this.showLoading();

      const jqXhr = $.ajax({
        url: this.options.route,
        dataType: 'json',
        data: this.$filter.serialize(),
      });

      // On success or failured, always hide the loading container.
      jqXhr.then(
        () => this.hideLoading(),
        () => this.hideLoading(),
      );

      return jqXhr;
    }

    /**
     * Show the loading container.
     *
     * @return void
     */
    showLoading() {
      this.$loading.removeClass(this.options.hiddenClass);
    }

    /**
     * Hide the loading container.
     *
     * @return void
     */
    hideLoading() {
      this.$loading.addClass(this.options.hiddenClass);
    }

    /**
     * Trigger the window print event.
     *
     * @return void
     */
    static onPrint() {
      window.print();
    }
  }

  /**
   * Cache a copy of any existing jQuery plugins with this name to provide
   * noConflict support.
   */
  const noConflict = $.fn[pluginName];

  /**
  * Dictionary of default values to be assigned to the plugin.  This allows globally
  * changing default values without needing to override defaults on a per
  * plugin basis.
  * @see $.fn.[pluginName].defaults();
  */
  let defaults = {
    route: '',
    autoLoad: true,
    displayTableSelector: '.js-display-table',
    printTableSelector: '.js-print-table',
    loadingSelector: '.js-loading',
    updateBtnSelector: '.js-update',
    printBtnSelector: '.js-print',
    dateToSelector: '.js-date-to',
    dateFromSelector: '.js-date-from',
    hiddenClass: 'hidden',
  };

  /**
   * Wrapper around plugin initialisation logic, allowing optional
   * re-initialisation and method calling with return values.
   * @return mixed Defaults to the collection the method was called on, else
   * when calling a method on the plugin, returns the first returned value
   * from the collection.
   */
  $.fn[pluginName] = function init(...args) {
    /**
     * Grab any additional arguments that may have been passed through.
     * This allows passing arguments to methods called on our plugin.
     */
    const action = args[0];
    const actionArgs = args.slice(1);
    const collection = this;
    let result = this;

    Array.prototype
      .forEach
      .call(collection, (el) => {
        const $el = $(el);
        const data = $.extend({}, $el.data());
        let instance = $el.data(pluginName);

        /**
         * If our plugin is already instantiated, remove it from the data
         * object to avoid merging an old instance of the plugin into our
         * options.
         */
        if (instance instanceof Plugin) {
          delete data[pluginName];
        }

        const options = $.extend(
          true,
          {},
          defaults,
          instance instanceof Plugin && instance.options,
          data,
          typeof action === 'object' && action,
        );

        /**
         * If an instance of our plugin has not yet been initialised, or if
         * the action is an object and thus should be treated as a new
         * configuration, (re)initialise the plugin and store it on the
         * element.
         */
        if ((instance instanceof Plugin) === false || typeof action === 'object') {
          instance = new Plugin(el, options);
          $el.data(pluginName, instance);
        }

        if (typeof action === 'string' && typeof instance[action] === 'function') {
          const tmp = instance[action](...actionArgs);

          if (result === collection && tmp !== undefined) {
            result = tmp;
          }
        }
      });

    return result;
  };

  /**
   * Restores any previously bound plugins with the same name as ours and
   * returns an instance of our jQuery bindings to allow noConflict support.
   * @return function
   */
  $.fn[pluginName].noConflict = () => {
    const current = $.fn[pluginName];

    $.fn[pluginName] = noConflict;

    return current;
  };

  /**
   * Return or update our default options object to allow global default
   * modification.
   * @param object newDefaults new default dictionary to merge.
   * @return object
   */
  $.fn[pluginName].defaults = (newDefaults) => {
    if (typeof newDefaults === 'object') {
      defaults = $.extend(
        true,
        {},
        defaults,
        newDefaults,
      );
    }

    return defaults;
  };
}));
