;(((root, factory) => { // eslint-disable-line no-extra-semi
  if (typeof define === 'function' && define.amd) {
    /**
     * Register our plugin as an anonymous module, defining jQuery as a
     * dependency.
     */
    define(['jquery'], factory);
  } else {
    /**
     * AMD is not available, so use the copy of jQuery attached to our
     * current root (Window by default).
     */
    factory(root.jQuery);
  }
})(window, (jQuery) => {
  const $ = jQuery; // eslint-disable-line id-length

  /**
   * Define the name of your plugin;  this name will be used to access the
   * plugin through jQuery, e.g. $('body').defaultPluginName().
   */
  const pluginName = 'repairsHistory';

  class Plugin {

    /**
     * The Plugin object construtor.  Initialisation logic should be placed here
     * following the assignment statements.
     */
    constructor(el, options) {
      this.options = options;
      this.element = el;
      this.$element = $(el);
      this.$nextBtn = this.$element.find(this.options.nextBtnSelector);
      this.$prevBtn = this.$element.find(this.options.prevBtnSelector);
      this.$tryAgainBtn = this.$element.find(this.options.tryAgainBtnSelector);
      this.$body = this.$element.find(this.options.bodySelector);
      this.$page = this.$element.find(this.options.pageSelector);
      this.$loading = this.$element.find(this.options.loadingSelector);
      this.$errors = this.$element.find(this.options.errorsSelector);

      this.onNextClickHander = this.onNextClickHander.bind(this);
      this.onPrevClickHander = this.onPrevClickHander.bind(this);
      this.onTryAgainClickHander = this.onTryAgainClickHander.bind(this);

      this.unregisterBindings();
      this.registerBindings();

      if (this.options.autoload) {
        this.requestHistory();
      }
    }

    /**
     * Register any relevant event bindings for this Plugin.
     * @return void
     */
    registerBindings() {
      this.$nextBtn.on(`click.${pluginName}`, this.onNextClickHander);
      this.$prevBtn.on(`click.${pluginName}`, this.onPrevClickHander);
      this.$tryAgainBtn.on(`click.${pluginName}`, this.onTryAgainClickHander);
    }

    /**
     * Unregister any event bindings bound by the Plugin.
     * @return void
     */
    unregisterBindings() {
      this.$nextBtn.off(`.${pluginName}`);
      this.$prevBtn.off(`.${pluginName}`);
      this.$tryAgainBtn.off(`.${pluginName}`);
    }

    /**
     * Handle the next button being clicked.
     *
     * @param Event evt
     *
     * @return void
     */
    onNextClickHander(evt) {
      evt.preventDefault();

      this.options.offset += this.options.increment;

      if (this.options.total !== null) {
        this.options.offset = Math.min(this.options.offset, this.options.total);
      }

      this.requestHistory();
    }

    /**
     * Handle the previous button being clicked.
     *
     * @param Event evt
     *
     * @return void
     */
    onPrevClickHander(evt) {
      evt.preventDefault();

      this.options.offset = Math.max(
        this.options.offset - this.options.increment,
        0,
      );

      this.requestHistory();
    }

    /**
     * Handle the try again button being clicked.
     *
     * @param Event evt
     *
     * @return void
     */
    onTryAgainClickHander(evt) {
      evt.preventDefault();

      this.requestHistory();
    }

    /**
     * Request the repairs history from the server.
     *
     * @return JqueryXHR
     */
    requestHistory() {
      this.showLoading();
      this.hideErrors();

      // Clear the existing content.
      this.updateContent('', '');

      // Disable and hide the next and previous buttons.
      this.$nextBtn.addClass(this.options.disabledClass);
      this.$prevBtn.addClass(this.options.disabledClass);

      const jqXhr = $.ajax({
        url: this.options.route,
        dataType: 'json',
        method: 'GET',
        data: {
          status: this.options.status,
          limit: this.options.limit,
          offset: this.options.offset,
        },
      });

      // Whether the request succeeds or fails, hide the loading screen.
      jqXhr.then(
        () => this.hideLoading(),
        () => this.hideLoading(),
      );

      jqXhr.then((response) => {
        // Validate whether the next and previous buttons should be enabled or
        // disbaled.
        this.validateButtons(response.total);

        // Update the content area.
        this.updateContent(response.content, response.pageInfo);

        this.options.total = response.total;
      });

      // Handle errors if the request fails.
      jqXhr.fail(() => this.showErrors());

      return jqXhr;
    }

    /**
     * Update the content of the repairs history with the given body and page info.
     *
     * @return void
     */
    updateContent(body, pageInfo) {
      this.$body.html(body);

      this.$page.html(pageInfo);

      $('[data-toggle="tooltip"]').tooltip();
    }

    /**
     * Validate whether the next and previous buttons should be enabled.
     *
     * @return void
     */
    validateButtons(total) {
      if (total > this.options.limit) {
        this.$nextBtn.removeClass(this.options.hiddenClass);
        this.$prevBtn.removeClass(this.options.hiddenClass);
      }

      if (this.options.offset > 1) {
        this.$prevBtn.removeClass(this.options.disabledClass);
      }

      const range = (this.options.offset - 1) + this.options.limit;

      if (range < total) {
        this.$nextBtn.removeClass(this.options.disabledClass);
      }
    }

    /**
     * Show the loading container.
     *
     * @return void
     */
    showLoading() {
      this.$loading.removeClass(this.options.hiddenClass);
    }

    /**
     * Hide the loading container.
     *
     * @return void
     */
    hideLoading() {
      this.$loading.addClass(this.options.hiddenClass);
    }

    /**
     * Show the error container.
     *
     * @return void
     */
    showErrors() {
      this.$errors.removeClass(this.options.hiddenClass);
    }

    /**
     * Hide the error container.
     *
     * @return void
     */
    hideErrors() {
      this.$errors.addClass(this.options.hiddenClass);
    }
  }

  /**
   * Cache a copy of any existing jQuery plugins with this name to provide
   * noConflict support.
   */
  const noConflict = $.fn[pluginName];

  /**
  * Dictionary of default values to be assigned to the plugin.  This allows globally
  * changing default values without needing to override defaults on a per
  * plugin basis.
  * @see $.fn.[pluginName].defaults();
  */
  let defaults = {
    route: '',
    loadingSelector: '.js-loading',
    errorsSelector: '.js-errors',
    nextBtnSelector: '.js-next-btn',
    prevBtnSelector: '.js-prev-btn',
    tryAgainBtnSelector: '.js-try-again-btn',
    pageSelector: '.js-page',
    bodySelector: '.js-body',
    hiddenClass: 'hidden',
    disabledClass: 'disabled',
    limit: 10,
    offset: 0,
    increment: 10,
    total: null,
    status: 'Open',
    autoload: false,
  };

  /**
   * Wrapper around plugin initialisation logic, allowing optional
   * re-initialisation and method calling with return values.
   * @return mixed Defaults to the collection the method was called on, else
   * when calling a method on the plugin, returns the first returned value
   * from the collection.
   */
  $.fn[pluginName] = function init(...args) {
    /**
     * Grab any additional arguments that may have been passed through.
     * This allows passing arguments to methods called on our plugin.
     */
    const action = args[0];
    const actionArgs = args.slice(1);
    const collection = this;
    let result = this;

    Array.prototype
      .forEach
      .call(collection, (el) => {
        const $el = $(el);
        const data = $.extend({}, $el.data());
        let instance = $el.data(pluginName);

        /**
         * If our plugin is already instantiated, remove it from the data
         * object to avoid merging an old instance of the plugin into our
         * options.
         */
        if (instance instanceof Plugin) {
          delete data[pluginName];
        }

        const options = $.extend(
          true,
          {},
          defaults,
          instance instanceof Plugin && instance.options,
          data,
          typeof action === 'object' && action,
        );

        /**
         * If an instance of our plugin has not yet been initialised, or if
         * the action is an object and thus should be treated as a new
         * configuration, (re)initialise the plugin and store it on the
         * element.
         */
        if ((instance instanceof Plugin) === false || typeof action === 'object') {
          instance = new Plugin(el, options);
          $el.data(pluginName, instance);
        }

        if (typeof action === 'string' && typeof instance[action] === 'function') {
          const tmp = instance[action](...actionArgs);

          if (result === collection && tmp !== undefined) {
            result = tmp;
          }
        }
      });

    return result;
  };

  /**
   * Restores any previously bound plugins with the same name as ours and
   * returns an instance of our jQuery bindings to allow noConflict support.
   * @return function
   */
  $.fn[pluginName].noConflict = () => {
    const current = $.fn[pluginName];

    $.fn[pluginName] = noConflict;

    return current;
  };

  /**
   * Return or update our default options object to allow global default
   * modification.
   * @param object newDefaults new default dictionary to merge.
   * @return object
   */
  $.fn[pluginName].defaults = (newDefaults) => {
    if (typeof newDefaults === 'object') {
      defaults = $.extend(
        true,
        {},
        defaults,
        newDefaults,
      );
    }

    return defaults;
  };
}));
