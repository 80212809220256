;(((root, factory) => { // eslint-disable-line no-extra-semi
  if (typeof define === 'function' && define.amd) {
    /**
     * Register our plugin as an anonymous module, defining jQuery as a
     * dependency.
     */
    define(['jquery'], factory);
  } else {
    /**
     * AMD is not available, so use the copy of jQuery attached to our
     * current root (Window by default).
     */
    factory(root.jQuery);
  }
})(window, (jQuery) => {
  const $ = jQuery; // eslint-disable-line id-length

  /**
   * Define the name of your plugin;  this name will be used to access the
   * plugin through jQuery, e.g. $('body').defaultPluginName().
   */
  const pluginName = 'userMaintenanceTenancyDetails';

  class Plugin {

    /**
     * The Plugin object construtor.  Initialisation logic should be placed here
     * following the assignment statements.
     */
    constructor(el, options) {
      this.options = options;
      this.element = el;
      this.$element = $(el);
      this.$loading = this.$element.find(this.options.loadingSelector);
      this.$error = this.$element.find(this.options.errorSelector);
      this.$tenancySelector = this.$element.find(this.options.tenancySelectorSelector);
      this.$tenancyDetails = this.$element.find(this.options.tenancyDetailsSelector);
      this.$tenancyReference = this.$tenancyDetails.find(this.options.tenancyReferenceSelector);
      this.$address = this.$tenancyDetails.find(this.options.addressSelector);
      this.$postcode = this.$tenancyDetails.find(this.options.postcodeSelector);
      this.$startDate = this.$tenancyDetails.find(this.options.startDateSelector);
      this.$accountBalance = this.$tenancyDetails.find(this.options.accountBalanceSelector);
      this.$weeklyRent = this.$tenancyDetails.find(this.options.weeklyRentSelector);

      this.showTenancyDetailsHandler = this.showTenancyDetailsHandler.bind(this);
      this.tenancySetHandler = this.tenancySetHandler.bind(this);

      this.unregisterBindings();
      this.registerBindings();
    }

    /**
     * Register any relevant event bindings for this Plugin.
     * @return void
     */
    registerBindings() {
      this.$element.on(`show.bs.collapse.${pluginName}`, this.showTenancyDetailsHandler);
      this.$tenancySelector.on(`TenancySet.${pluginName}`, this.tenancySetHandler);
    }

    /**
     * Unregister any event bindings bound by the Plugin.
     * @return void
     */
    unregisterBindings() {
      this.$element.off(`.${pluginName}`);
      this.$tenancySelector.off(`.${pluginName}`);
    }

    /**
     * Handle the tenancy set event from the tenancy selector.
     *
     * @param Event event
     * @param string reference The tenancy reference that was set.
     *
     * @return void
     */
    tenancySetHandler(event, reference) {
      this.getTenancyDetails(reference).then((details) => {
        this.setTenancyDetails(details);
        this.showTenancyDetails();
      });
    }

    /**
     * Handle the show even for the bootstrap tenancy details panel.
     *
     * @erturn void
     */
    showTenancyDetailsHandler() {
      const tenancy = this.$tenancySelector.tenancySelector('getSelectedTenancy');

      this.getTenancyDetails(tenancy.TenancyReference).then((details) => {
        this.setTenancyDetails(details);
        this.showTenancyDetails();
      });
    }

    /**
     * Get the tenancy details from the server.
     *
     * @param string reference The tenancy reference to retrieve.
     *
     * @return JqueryXHR
     */
    getTenancyDetails(reference) {
      this.showLoading();
      this.hideError();
      this.hideTenancyDetails();

      const jqXhr = $.get({
        url: this.options.tenancyDetailsRoute,
        data: { reference },
        dataType: 'json',
      });

      return jqXhr.then(
        (response) => {
          this.hideLoading();

          if (response.success === true) {
            return $.Deferred().resolve(response.details);
          }

          return $.Deferred().reject(response.details);
        },
      ).fail(
        () => {
          this.hideLoading();
          this.showError();
        },
      );
    }

    /**
     * Set the tenancy details text.
     *
     * @param Object tenancy Object containing keyed tenancy details.
     *
     * @return void
     */
    setTenancyDetails(tenancy) {
      this.$tenancyReference.text(tenancy.TenancyReference);
      this.$address.text(tenancy.Address);
      this.$postcode.text(tenancy.Postcode);
      this.$startDate.text(tenancy.StartDate);
      this.$accountBalance.html(tenancy.AccountBalance);
      this.$weeklyRent.html(tenancy.WeeklyRent);
    }

    /**
     * Show the tenancy details container.
     *
     * @return void
     */
    showTenancyDetails() {
      this.$tenancyDetails.removeClass(this.options.hiddenClass);
    }

    /**
     * Hide the tenancy details container.
     *
     * @return void
     */
    hideTenancyDetails() {
      this.$tenancyDetails.addClass(this.options.hiddenClass);
    }

    /**
     * Show the loading container.
     *
     * @return void
     */
    showLoading() {
      this.$loading.removeClass(this.options.hiddenClass);
    }

    /**
     * Hide the loading container.
     *
     * @return void
     */
    hideLoading() {
      this.$loading.addClass(this.options.hiddenClass);
    }

    /**
     * Show the error container.
     *
     * @return void
     */
    showError() {
      this.$error.removeClass(this.options.hiddenClass);
    }

    /**
     * Hide the error container.
     *
     * @return void
     */
    hideError() {
      this.$error.addClass(this.options.hiddenClass);
    }
  }

  /**
   * Cache a copy of any existing jQuery plugins with this name to provide
   * noConflict support.
   */
  const noConflict = $.fn[pluginName];

  /**
  * Dictionary of default values to be assigned to the plugin.  This allows globally
  * changing default values without needing to override defaults on a per
  * plugin basis.
  * @see $.fn.[pluginName].defaults();
  */
  let defaults = {
    tenancyDetailsRoute: '',
    errorSelector: '.js-tenancy-error',
    loadingSelector: '.js-tenancy-loading',
    tenancySelectorSelector: '#tenancy-selector',
    tenancyDetailsSelector: '.js-tenancy-details',
    tenancyReferenceSelector: '.js-tenancy-reference',
    addressSelector: '.js-address',
    postcodeSelector: '.js-postcode',
    startDateSelector: '.js-start-date',
    accountBalanceSelector: '.js-account-balance',
    weeklyRentSelector: '.js-weekly-rent',
    hiddenClass: 'hidden',
  };

  /**
   * Wrapper around plugin initialisation logic, allowing optional
   * re-initialisation and method calling with return values.
   * @return mixed Defaults to the collection the method was called on, else
   * when calling a method on the plugin, returns the first returned value
   * from the collection.
   */
  $.fn[pluginName] = function init(...args) {
    /**
     * Grab any additional arguments that may have been passed through.
     * This allows passing arguments to methods called on our plugin.
     */
    const action = args[0];
    const actionArgs = args.slice(1);
    const collection = this;
    let result = this;

    Array.prototype
      .forEach
      .call(collection, (el) => {
        const $el = $(el);
        const data = $.extend({}, $el.data());
        let instance = $el.data(pluginName);

        /**
         * If our plugin is already instantiated, remove it from the data
         * object to avoid merging an old instance of the plugin into our
         * options.
         */
        if (instance instanceof Plugin) {
          delete data[pluginName];
        }

        const options = $.extend(
          true,
          {},
          defaults,
          instance instanceof Plugin && instance.options,
          data,
          typeof action === 'object' && action,
        );

        /**
         * If an instance of our plugin has not yet been initialised, or if
         * the action is an object and thus should be treated as a new
         * configuration, (re)initialise the plugin and store it on the
         * element.
         */
        if ((instance instanceof Plugin) === false || typeof action === 'object') {
          instance = new Plugin(el, options);
          $el.data(pluginName, instance);
        }

        if (typeof action === 'string' && typeof instance[action] === 'function') {
          const tmp = instance[action](...actionArgs);

          if (result === collection && tmp !== undefined) {
            result = tmp;
          }
        }
      });

    return result;
  };

  /**
   * Restores any previously bound plugins with the same name as ours and
   * returns an instance of our jQuery bindings to allow noConflict support.
   * @return function
   */
  $.fn[pluginName].noConflict = () => {
    const current = $.fn[pluginName];

    $.fn[pluginName] = noConflict;

    return current;
  };

  /**
   * Return or update our default options object to allow global default
   * modification.
   * @param object newDefaults new default dictionary to merge.
   * @return object
   */
  $.fn[pluginName].defaults = (newDefaults) => {
    if (typeof newDefaults === 'object') {
      defaults = $.extend(
        true,
        {},
        defaults,
        newDefaults,
      );
    }

    return defaults;
  };
}));
