;(((root, factory) => { // eslint-disable-line no-extra-semi
  if (typeof define === 'function' && define.amd) {
    /**
     * Register our plugin as an anonymous module, defining jQuery as a
     * dependency.
     */
    define(['jquery'], factory);
  } else {
    /**
     * AMD is not available, so use the copy of jQuery attached to our
     * current root (Window by default).
     */
    factory(root.jQuery);
  }
})(window, (jQuery) => {
  const $ = jQuery; // eslint-disable-line id-length

  /**
   * Define the name of your plugin;  this name will be used to access the
   * plugin through jQuery, e.g. $('body').defaultPluginName().
   */
  const pluginName = 'tenancySelector';

  class Plugin {

    /**
     * The Plugin object construtor.  Initialisation logic should be placed here
     * following the assignment statements.
     */
    constructor(el, options) {
      this.options = options;
      this.element = el;
      this.$element = $(el);
      this.$modal = $(this.options.modalSelector);
      this.$address = this.$element.find(this.options.addressSelector);

      this.tenancySelectedHandler = this.tenancySelectedHandler.bind(this);

      this.unregisterBindings();
      this.registerBindings();

      this.checkDefaultTenancy();
    }

    /**
     * Register any relevant event bindings for this Plugin.
     * @return void
     */
    registerBindings() {
      this.$modal.on(`TenancySelected.${pluginName}`, this.tenancySelectedHandler);
    }

    /**
     * Unregister any event bindings bound by the Plugin.
     * @return void
     */
    unregisterBindings() {
      this.$modal.off(`.${pluginName}`);
    }

    /**
     * Handle the tenancy selected event.
     *
     * @param Event jQuery Event object.
     * @param string reference Tenancy reference.
     * @param string address Tenancy address.
     *
     * @return void
     */
    tenancySelectedHandler(event, reference, address) {
      this.setSelectedTenancy(reference, address);
    }


    /**
     * Set the selected tenancy based on the given reference and address.
     *
     * @param string reference Tenancy reference.
     * @param string address Tenancy address.
     *
     * @return void
     */
    setSelectedTenancy(reference, address) {
      this.setAddress(address);

      const redirectRequired = (
        this.options.redirectRoute
        && (
          this.options.selectedTenancy === null
          || this.options.selectedTenancy.TenancyReference !== reference
        )
      );

      this.options.selectedTenancy = {
        TenancyReference: reference,
        Address: address,
      };

      this.triggerTenancySet(reference);

      if (this.options.storeOnSelect) {
        try {
          window.localStorage.setItem(this.options.referenceStorageKey, reference);
          window.localStorage.setItem(this.options.addressStorageKey, address);
        } catch (err) {
          // If the user doesn't support local storage, nothing can be done, so
          // just supress the error and move along.
        }
      }

      if (redirectRequired) {
        this.redirect(reference);
      }
    }

    /**
     * Redirect the browser to the given tenancy reference.
     *
     * @param string reference Tenancy reference.
     *
     * @return void
     */
    redirect(reference) {
      window.location = `${this.options.redirectRoute}/${reference}`;
    }

    /**
     * Set the address text.
     *
     * @param string address
     *
     * @return void
     */
    setAddress(address) {
      this.$address.text(address);
    }

    /**
     * Attempt to retrieve the previously selected tenancy stored in local storage.
     *
     * @return Object
     */
    getStoredTenancy() {
      const storedDetails = {
        reference: null,
        address: null,
      };

      try {
        storedDetails.reference = window.localStorage.getItem(this.options.referenceStorageKey);
        storedDetails.address = window.localStorage.getItem(this.options.addressStorageKey);
      } catch (err) {
        // If the user doesn't support local storage, nothing can be done, so
        // just supress the error and move along.
      }

      return storedDetails;
    }

    /**
     * Check if a default tenancy should be set and attempt to set the tenancy if
     * required.
     *
     * @return bool
     */
    checkDefaultTenancy() {
      if (this.options.selectedTenancy !== null) {
        const tenancy = this.options.selectedTenancy;

        this.setSelectedTenancy(
          tenancy.TenancyReference,
          `${tenancy.Address}, ${tenancy.Postcode}`,
        );

        return true;
      }

      if (this.options.selectableTenancies.length >= 1) {
        const tenancy = this.options.selectableTenancies[0];

        if (this.options.selectableTenancies === 1) {
          this.setSelectedTenancy(
            tenancy.TenancyReference,
            `${tenancy.Addres}, ${tenancy.Postcode}`,
          );

          return true;
        }

        if (this.options.restoreOnLoad) {
          const storedDetails = this.getStoredTenancy();

          if (storedDetails.reference !== null && storedDetails.address !== null) {
            const hasReference = this.options.selectableTenancies.some((availableTenancy) => {
              const parsedAvailable = parseInt(availableTenancy, 10);
              const parsedStored = parseInt(storedDetails.reference, 10);
              return parsedAvailable === parsedStored;
            });

            if (hasReference) {
              this.setSelectedTenancy(
                storedDetails.reference,
                storedDetails.address,
              );

              return true;
            }
          }
        }

        this.setSelectedTenancy(
          tenancy.TenancyReference,
          `${tenancy.Address}, ${tenancy.Postcode}`,
        );

        return true;
      }

      return false;
    }

    /**
     * Trigger the TenancySet event.
     *
     * @param string reference The ID of the selected tenancy.
     *
     * @return void
     */
    triggerTenancySet(reference) {
      const event = $.Event('TenancySet');

      this.$element.trigger(
        event,
        [
          reference,
        ],
      );
    }

    /**
     * Returns the currently selected tenancy.
     *
     * @return Object
     */
    getSelectedTenancy() {
      let tenancy = this.options.selectedTenancy;

      if (tenancy === null) {
        tenancy = {};
      }

      return {
        TenancyReference: tenancy.TenancyReference,
        Address: tenancy.Address,
      };
    }
  }

  /**
   * Cache a copy of any existing jQuery plugins with this name to provide
   * noConflict support.
   */
  const noConflict = $.fn[pluginName];

  /**
  * Dictionary of default values to be assigned to the plugin.  This allows globally
  * changing default values without needing to override defaults on a per
  * plugin basis.
  * @see $.fn.[pluginName].defaults();
  */
  let defaults = {
    referenceStorageKey: 'selectedTenancyReference',
    addressStorageKey: 'selectedTenancyAddress',
    modalSelector: '#tenancy-selector-modal',
    addressSelector: '.js-address-label',
    redirectRoute: null,
    selectedTenancy: null,
    selectableTenancies: [],
  };

  /**
   * Wrapper around plugin initialisation logic, allowing optional
   * re-initialisation and method calling with return values.
   * @return mixed Defaults to the collection the method was called on, else
   * when calling a method on the plugin, returns the first returned value
   * from the collection.
   */
  $.fn[pluginName] = function init(...args) {
    /**
     * Grab any additional arguments that may have been passed through.
     * This allows passing arguments to methods called on our plugin.
     */
    const action = args[0];
    const actionArgs = args.slice(1);
    const collection = this;
    let result = this;

    Array.prototype
      .forEach
      .call(collection, (el) => {
        const $el = $(el);
        const data = $.extend({}, $el.data());
        let instance = $el.data(pluginName);

        /**
         * If our plugin is already instantiated, remove it from the data
         * object to avoid merging an old instance of the plugin into our
         * options.
         */
        if (instance instanceof Plugin) {
          delete data[pluginName];
        }

        const options = $.extend(
          true,
          {},
          defaults,
          instance instanceof Plugin && instance.options,
          data,
          typeof action === 'object' && action,
        );

        /**
         * If an instance of our plugin has not yet been initialised, or if
         * the action is an object and thus should be treated as a new
         * configuration, (re)initialise the plugin and store it on the
         * element.
         */
        if ((instance instanceof Plugin) === false || typeof action === 'object') {
          instance = new Plugin(el, options);
          $el.data(pluginName, instance);
        }

        if (typeof action === 'string' && typeof instance[action] === 'function') {
          const tmp = instance[action](...actionArgs);

          if (result === collection && tmp !== undefined) {
            result = tmp;
          }
        }
      });

    return result;
  };

  /**
   * Restores any previously bound plugins with the same name as ours and
   * returns an instance of our jQuery bindings to allow noConflict support.
   * @return function
   */
  $.fn[pluginName].noConflict = () => {
    const current = $.fn[pluginName];

    $.fn[pluginName] = noConflict;

    return current;
  };

  /**
   * Return or update our default options object to allow global default
   * modification.
   * @param object newDefaults new default dictionary to merge.
   * @return object
   */
  $.fn[pluginName].defaults = (newDefaults) => {
    if (typeof newDefaults === 'object') {
      defaults = $.extend(
        true,
        {},
        defaults,
        newDefaults,
      );
    }

    return defaults;
  };
}));
