;(((root, factory) => { // eslint-disable-line no-extra-semi
  if (typeof define === 'function' && define.amd) {
    /**
     * Register our plugin as an anonymous module, defining jQuery as a
     * dependency.
     */
    define(['jquery'], factory);
  } else {
    /**
     * AMD is not available, so use the copy of jQuery attached to our
     * current root (Window by default).
     */
    factory(root.jQuery);
  }
})(window, (jQuery) => {
  const $ = jQuery; // eslint-disable-line id-length

  /**
   * Define the name of your plugin;  this name will be used to access the
   * plugin through jQuery, e.g. $('body').defaultPluginName().
   */
  const pluginName = 'register';

  class Plugin {

    /**
     * The Plugin object construtor.  Initialisation logic should be placed here
     * following the assignment statements.
     */
    constructor(el, options) {
      this.options = options;
      this.element = el;
      this.$element = $(el);
      this.$steps = this.$element.find(this.options.stepSelector);
      this.$progress = this.$element.find(this.options.progressSelector);
      this.$loading = this.$element.find(this.options.loadingSelector);

      this.nextStepHandler = this.nextStepHandler.bind(this);

      this.unregisterBindings();
      this.registerBindings();
    }

    /**
     * Register any relevant event bindings for this Plugin.
     * @return void
     */
    registerBindings() {
      this.$steps.on(`submission.successful.af.${pluginName}`, this.nextStepHandler);
    }

    /**
     * Unregister any event bindings bound by the Plugin.
     * @return void
     */
    unregisterBindings() {
      this.$steps.off(`.${pluginName}`);
    }

    /**
     * Event handler for the next button, moving to the next step of the
     * process.
     *
     * @param Event event
     *
     * @return void
     */
    nextStepHandler(event) {
      event.preventDefault();

      const $current = this.getCurrentStep();

      const $next = this.getNextStep();

      this.hideStep($current);
      this.incrementProgress();

      if (event.ajaxForm.payload.redirect) {
        // If we're redirecting, then show the loading spinner again whilst
        // the page loads.
        this.showLoading();
        window.location = event.ajaxForm.payload.redirect;
        return;
      }

      Plugin.setHiddenFields(event.ajaxForm.payload, $next);

      this.showStep($next);
    }

    /**
     * Get the current step.
     *
     * @return jQuery
     */
    getCurrentStep() {
      return this.$steps.filter(`:not(.${this.options.hiddenClass}):first`);
    }

    /**
     * Get the next step.
     *
     * @return jQuery
     */
    getNextStep() {
      return this.getCurrentStep()
        .nextAll(`.${this.options.hiddenClass}:first`);
    }

    /**
     * Show the given step.
     *
     * @param jQuery $step
     *
     * @return void
     */
    showStep($step) {
      $step.removeClass(this.options.hiddenClass);
    }

    /**
     * Hide the given step.
     *
     * @param jQuery $step
     *
     * @return void
     */
    hideStep($step) {
      $step.addClass(this.options.hiddenClass);
    }

    /**
     * Show the loading spinner.
     *
     * @return void
     */
    showLoading() {
      this.$loading.removeClass(this.options.hiddenClass);
    }

    /**
     * Hide the loading spinner.
     *
     * @return void
     */
    hideLoading() {
      this.$loading.addClass(this.options.hiddenClass);
    }

    /**
     * Increments the progress step.
     *
     * @return void
     */
    incrementProgress() {
      const $activeProgress = this.$progress.find(`li.${this.options.progressActiveClass}`);

      $activeProgress.addClass(this.options.progressCompletedClass);
      $activeProgress.removeClass(this.options.progressActiveClass);

      const nextSelector = `li:not(.${this.options.progressCompletedClass}):first`;

      this.$progress.find(nextSelector).addClass(this.options.progressActiveClass);
    }

    /**
     * Sets the given data on the hidden fields of the given step.
     *
     * @param Object data
     * @param jQuery $step
     *
     * @return void
     */
    static setHiddenFields(data, $step) {
      Object.keys(data).forEach((key) => {
        const field = Plugin.formatFieldName(key);
        $step.find(`.${field}-hidden`).val(data[key]);
      });
    }

    /**
     * Format the given field name to match the style used in CSS/IDs.
     *
     * @return string
     */
    static formatFieldName(field) {
      return field.replace(/_/g, '-');
    }
  }

  /**
   * Cache a copy of any existing jQuery plugins with this name to provide
   * noConflict support.
   */
  const noConflict = $.fn[pluginName];

  /**
   * Dictionary of default values to be assigned to the plugin.  This allows globally
   * changing default values without needing to override defaults on a per
   * plugin basis.
   * @see $.fn.[pluginName].defaults();
   */
  let defaults = {
    stepSelector: '.js-step',
    nextBtnSelector: '.js-next-btn',
    loadingSelector: '.js-loading:first',
    progressSelector: '.js-progress',
    progressActiveClass: 'active',
    progressCompletedClass: 'completed',
    hiddenClass: 'hidden',
    errorClass: 'has-error',
  };

  /**
   * Wrapper around plugin initialisation logic, allowing optional
   * re-initialisation and method calling with return values.
   * @return mixed Defaults to the collection the method was called on, else
   * when calling a method on the plugin, returns the first returned value
   * from the collection.
   */
  $.fn[pluginName] = function init(...args) {
    /**
     * Grab any additional arguments that may have been passed through.
     * This allows passing arguments to methods called on our plugin.
     */
    const action = args[0];
    const actionArgs = args.slice(1);
    const collection = this;
    let result = this;

    Array.prototype
      .forEach
      .call(collection, (el) => {
        const $el = $(el);
        const data = $.extend({}, $el.data());
        let instance = $el.data(pluginName);

        /**
         * If our plugin is already instantiated, remove it from the data
         * object to avoid merging an old instance of the plugin into our
         * options.
         */
        if (instance instanceof Plugin) {
          delete data[pluginName];
        }

        const options = $.extend(
          true,
          {},
          defaults,
          instance instanceof Plugin && instance.options,
          data,
          typeof action === 'object' && action,
        );

        /**
         * If an instance of our plugin has not yet been initialised, or if
         * the action is an object and thus should be treated as a new
         * configuration, (re)initialise the plugin and store it on the
         * element.
         */
        if ((instance instanceof Plugin) === false || typeof action === 'object') {
          instance = new Plugin(el, options);
          $el.data(pluginName, instance);
        }

        if (typeof action === 'string' && typeof instance[action] === 'function') {
          const tmp = instance[action](...actionArgs);

          if (result === collection && tmp !== undefined) {
            result = tmp;
          }
        }
      });

    return result;
  };

  /**
   * Restores any previously bound plugins with the same name as ours and
   * returns an instance of our jQuery bindings to allow noConflict support.
   * @return function
   */
  $.fn[pluginName].noConflict = () => {
    const current = $.fn[pluginName];

    $.fn[pluginName] = noConflict;

    return current;
  };

  /**
   * Return or update our default options object to allow global default
   * modification.
   * @param object newDefaults new default dictionary to merge.
   * @return object
   */
  $.fn[pluginName].defaults = (newDefaults) => {
    if (typeof newDefaults === 'object') {
      defaults = $.extend(
        true,
        {},
        defaults,
        newDefaults,
      );
    }

    return defaults;
  };
}));
