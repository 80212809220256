;(((root, factory) => { // eslint-disable-line no-extra-semi
  if (typeof define === 'function' && define.amd) {
    /**
     * Register our plugin as an anonymous module, defining jQuery as a
     * dependency.
     */
    define(['jquery'], factory);
  } else {
    /**
     * AMD is not available, so use the copy of jQuery attached to our
     * current root (Window by default).
     */
    factory(root.jQuery);
  }
})(window, (jQuery) => {
  const $ = jQuery; // eslint-disable-line id-length

  /**
   * Define the name of your plugin;  this name will be used to access the
   * plugin through jQuery, e.g. $('body').defaultPluginName().
   */
  const pluginName = 'ajaxForm';

  class Plugin {

    /**
     * The Plugin object construtor.  Initialisation logic should be placed here
     * following the assignment statements.
     */
    constructor(el, options) {
      this.options = options;
      this.element = el;
      this.$element = $(el);
      this.$form = this.$element.is('form') ? this.$element : this.$element.find(this.options.formSelector);
      this.$submit = this.$form.find('[type=submit]');
      this.$loading = this.$element.find(this.options.loadingSelector);
      this.$errors = this.$element.find(this.options.errorsSelector);
      this.$generalError = this.$element.find(this.options.generalErrorSelector);
      this.$successFeedback = this.$element.find(this.options.successFeedbackSelector);

      this.onSubmitHandler = this.onSubmitHandler.bind(this);

      this.unregisterBindings();
      this.registerBindings();
    }

    /**
     * Register any relevant event bindings for this Plugin.
     * @return void
     */
    registerBindings() {
      this.$element.on(`submit.${pluginName}`, this.onSubmitHandler);
    }

    /**
     * Unregister any event bindings bound by the Plugin.
     * @return void
     */
    unregisterBindings() {
      this.$element.off(`.${pluginName}`);
    }

    /**
     * Handle the form submit event.
     *
     * @param Event evt
     *
     * @return void
     */
    onSubmitHandler(evt) {
      evt.preventDefault();

      this.submitForm();
    }

    /**
     * Submit the form and process the response.
     *
     * @return jQueryXHR
     */
    submitForm() {
      this.clearErrors();
      this.hideSuccessFeedback();
      this.disableSubmit();

      if (this.hasLoader()) {
        this.showLoading();
      }

      const jqXhr = $.ajax({
        url: this.$form.attr('action'),
        method: this.$form.attr('method'),
        dataType: 'json',
        data: this.$form.serialize(),
      });

      jqXhr.then(
        () => {
          if (this.hasLoader()) {
            this.hideLoading();
          }
        },
        () => {
          if (this.hasLoader()) {
            this.hideLoading();
          }
        },
      );

      jqXhr.fail((xhr) => {
        this.enableSubmit();

        if (this.options.useRecaptcha) {
          this.resetRecaptcha();
        }

        if (xhr.status === 422) {
          return this.processErrors(xhr.responseJSON.errors);
        }

        return this.showGeneralError();
      });

      jqXhr.then((response) => {
        const event = this.notifySuccess(response);

        if (event.isDefaultPrevented()) {
          return;
        }

        this.showSuccessFeedback();

        if (this.options.allowMultipleSubmissions) {
          this.enableSubmit();

          if (this.options.useRecaptcha) {
            this.resetRecaptcha();
          }
        }
      });

      return jqXhr;
    }

    /**
     * Reset the form.
     *
     * @return void
     */
    reset() {
      this.clearErrors();
      this.hideSuccessFeedback();
      this.enableSubmit();

      if (this.options.useRecaptcha) {
        this.resetRecaptcha();
      }
    }

    /**
     * Disable the submit button.
     *
     * @return void
     */
    disableSubmit() {
      this.$submit.addClass(this.options.disabledClass);
      this.$submit.prop('disabled', true);
    }

    /**
     * Disable the submit button.
     *
     * @return void
     */
    enableSubmit() {
      this.$submit.removeClass(this.options.disabledClass);
      this.$submit.prop('disabled', false);
    }

    /**
     * Emit an event notify successful submission of the form.
     *
     * @return Event
     */
    notifySuccess(payload) {
      const event = $.Event('submission.successful.af');

      event.ajaxForm = {
        payload,
        plugin: this,
      };

      this.$element.trigger(event);

      return event;
    }

    /**
     * Clear any errors from the form.
     */
    clearErrors() {
      // Remove the error class.
      this.$form.find(this.options.formGroupSelector).removeClass(this.options.errorClass);

      // Hide the general error notice.
      this.hideGeneralError();

      // Clear any error text.
      this.$errors.text('');
    }

    /**
     * Process any validation errors.
     *
     * @param Object errors
     *
     * @return void
     */
    processErrors(errors) {
      this.$errors.each((idx, field) => {
        const $field = $(field);
        const hasField = Object.prototype.hasOwnProperty.call(errors, $field.data('field'));
        if (hasField) {
          $field.parents(this.options.formGroupSelector).addClass(this.options.errorClass);
          $field.text(errors[$field.data('field')][0]);
        }
      });
    }

    /**
     * Show the general error notification.
     *
     * @return void
     */
    showGeneralError() {
      this.$generalError.removeClass(this.options.hiddenClass);
    }

    /**
     * Hide the general error notice.
     *
     * @return void
     */
    hideGeneralError() {
      this.$generalError.addClass(this.options.hiddenClass);
    }

    /**
     * Show the success feedback container.
     *
     * @return void
     */
    showSuccessFeedback() {
      this.$successFeedback.removeClass(this.options.hiddenClass);
    }

    /**
     * Hide the success feedback container.
     *
     * @return void
     */
    hideSuccessFeedback() {
      this.$successFeedback.addClass(this.options.hiddenClass);
    }

    /**
     * Reset the reCaptcha for re-use.
     *
     * @return void
     */
    resetRecaptcha() {
      this.options.grecaptcha.reset(this.options.reCaptchaID);
    }

    /**
     * Show the loading container.
     *
     * @return void
     */
    showLoading() {
      this.$loading.removeClass(this.options.hiddenClass);
    }

    /**
     * Hide the loading container.
     *
     * @return void
     */
    hideLoading() {
      this.$loading.addClass(this.options.hiddenClass);
    }

    /**
     * Return whether the plugin has been initialised with a loading screen.
     *
     * @return void
     */
    hasLoader() {
      return this.$loading.length > 0;
    }
  }

  /**
   * Cache a copy of any existing jQuery plugins with this name to provide
   * noConflict support.
   */
  const noConflict = $.fn[pluginName];

  /**
  * Dictionary of default values to be assigned to the plugin.  This allows globally
  * changing default values without needing to override defaults on a per
  * plugin basis.
  * @see $.fn.[pluginName].defaults();
  */
  let defaults = {
    loadingSelector: '.js-loading',
    errorsSelector: '.js-errors',
    formSelector: '.js-form',
    formGroupSelector: '.form-group',
    successFeedbackSelector: '.js-success',
    generalErrorSelector: '.js-general-error',
    hiddenClass: 'hidden',
    disabledClass: 'disabled',
    errorClass: 'has-error',
    allowMultipleSubmissions: false,
    useRecaptcha: false,
    grecaptcha: undefined,
    reCaptchaID: undefined,
  };

  /**
   * Wrapper around plugin initialisation logic, allowing optional
   * re-initialisation and method calling with return values.
   * @return mixed Defaults to the collection the method was called on, else
   * when calling a method on the plugin, returns the first returned value
   * from the collection.
   */
  $.fn[pluginName] = function init(...args) {
    /**
     * Grab any additional arguments that may have been passed through.
     * This allows passing arguments to methods called on our plugin.
     */
    const action = args[0];
    const actionArgs = args.slice(1);
    const collection = this;
    let result = this;

    Array.prototype
      .forEach
      .call(collection, (el) => {
        const $el = $(el);
        const data = $.extend({}, $el.data());
        let instance = $el.data(pluginName);

        /**
         * If our plugin is already instantiated, remove it from the data
         * object to avoid merging an old instance of the plugin into our
         * options.
         */
        if (instance instanceof Plugin) {
          delete data[pluginName];
        }

        const options = $.extend(
          true,
          {},
          defaults,
          instance instanceof Plugin && instance.options,
          data,
          typeof action === 'object' && action,
        );

        /**
         * If an instance of our plugin has not yet been initialised, or if
         * the action is an object and thus should be treated as a new
         * configuration, (re)initialise the plugin and store it on the
         * element.
         */
        if ((instance instanceof Plugin) === false || typeof action === 'object') {
          instance = new Plugin(el, options);
          $el.data(pluginName, instance);
        }

        if (typeof action === 'string' && typeof instance[action] === 'function') {
          const tmp = instance[action](...actionArgs);

          if (result === collection && tmp !== undefined) {
            result = tmp;
          }
        }
      });

    return result;
  };

  /**
   * Restores any previously bound plugins with the same name as ours and
   * returns an instance of our jQuery bindings to allow noConflict support.
   * @return function
   */
  $.fn[pluginName].noConflict = () => {
    const current = $.fn[pluginName];

    $.fn[pluginName] = noConflict;

    return current;
  };

  /**
   * Return or update our default options object to allow global default
   * modification.
   * @param object newDefaults new default dictionary to merge.
   * @return object
   */
  $.fn[pluginName].defaults = (newDefaults) => {
    if (typeof newDefaults === 'object') {
      defaults = $.extend(
        true,
        {},
        defaults,
        newDefaults,
      );
    }

    return defaults;
  };
}));
